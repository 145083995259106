/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p",
    h3: "h3",
    code: "code",
    hr: "hr"
  }, _provideComponents(), props.components), {Type, Params} = _components;
  if (!Params) _missingMdxReference("Params", true);
  if (!Type) _missingMdxReference("Type", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, {
    id: "概要",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#%E6%A6%82%E8%A6%81",
    "aria-label": "概要 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), "概要"), "\n", React.createElement(_components.p, null, "Autoplayは自動再生の機能をスライダーに実装するためのコンポーネントです。このコンポーネントは", React.createElement(_components.a, {
    href: "/guides/events/"
  }, "いくつかのイベント"), "を配信します。"), "\n", React.createElement(_components.h2, {
    id: "メソッド",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#%E3%83%A1%E3%82%BD%E3%83%83%E3%83%89",
    "aria-label": "メソッド permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), "メソッド"), "\n", React.createElement(_components.h3, {
    id: "play",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#play",
    "aria-label": "play permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), React.createElement(_components.code, null, "play()")), "\n", React.createElement(Type, {
    code: 'play(): void'
  }), "\n", React.createElement(_components.p, null, "自動再生を開始します。"), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h3, {
    id: "pause",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#pause",
    "aria-label": "pause permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), React.createElement(_components.code, null, "pause()")), "\n", React.createElement(Type, {
    code: 'pause(): void'
  }), "\n", React.createElement(_components.p, null, "自動再生を停止します。"), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h3, {
    id: "ispaused",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#ispaused",
    "aria-label": "ispaused permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), React.createElement(_components.code, null, "isPaused()")), "\n", React.createElement(Type, {
    code: 'isPaused(): boolean'
  }), "\n", React.createElement(_components.p, null, "自動再生が現在停止中かどうかを確認します。"), "\n", React.createElement(Params, {
    ret: '停止中であれば`true`、再生中であれば`false`を返します。'
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
